@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Header Portion Start */
body{
  overflow-x: hidden;
  max-width: 100%;
}

.header p{
  font-family:'Lato';
  color: white;
  display: flex;
  align-items: center;
  margin: 0;

}
.header{
  background-color: #0D588F;
  display: flex;
  height: 54px;
  align-content: center;
  justify-content: space-between;
}
.headericons{
  color: white;
  height: 20px;
  width: 25px;

}

@media (max-width:480px){

  .header{
    width: 100%;
  }

  .header .right{
   display: none;
  }
  .header p{
    font-family:'Lato';
    color: white;
    display: flex;
    align-items: center;
    margin-right: 5vw;
    font-size: 0.9rem;
  
  }
}

/* Header Portion End */

.footericons{
  color: white;
  height: 30px;
  width: 35px;

}
.line{
  color: white;
  align-self: center; 
  margin-left: 10px;
  height: 25px;
  width: 30px;

}
.left{
  align-items: center;
  display:flex;
  margin-left: 10px;
  gap:5px;
}

.left a {
  text-decoration: none;
}

.right{
  align-items: right;
  display: flex;
  margin-right: 20px;
  align-items: center;
  gap:15px;
}

/* Navbar Portion Start */

.navbar-list{
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 50px;
  margin-right: 20px;
  font-family: 'poppins';
  font-size: 16px;
}

.Logoportion{
   
  height: 90px;
  display: flex;
  align-items: center;  
  justify-content: space-between;
  
}

.logo{
  height: 50px;
  width:309px;
  margin-left: 20px;
}

.link:hover,
.link:active{
  color : #1C8FE3;
  
}

.link{
  text-decoration: none;
  color:black;
}

.hamburger{
  display:none;
}

@media  (max-width:480px) {
  .navbar-list {
    display: none; /* Hide navbar links by default on smaller screens */
    flex-direction: column;
    position: absolute;
    top: 50px; /* Adjust based on your header height */
    left: -105vw;
    width: 100vw;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    font-size: 0.9rem;
    gap:20px;
    align-items: center;
    justify-content: center;
  }
  .navbar.active .navbar-list {
    display: flex; /* Show navbar links when active */
  }
  .hamburger {
    display: flex; /* Show hamburger menu icon */
    padding-left:2vw;
    padding-right: 3vw;
    color: #0D588F;
  }
  .logo{
    height: 40px;
    width:250px;
    margin-left: 3vw;
  }

}

/* Navbar Section end */

.dropdown{
  color:black;
  font-size: 30px;
  align-items: center;
  margin-left: 5px;
  margin-top: 6px;
}
.navli {
  display:flex;
  align-items: center;
}

.mainimage{
  width: 1440px;
  height: 620px;
  opacity: 90%;
  
}
@media (max-width:480px){
  .d-block.w-100{
   height: 50%;
  }
}
.content{
  color:#1C8FE3;
  font-family:'poppins';
  font-weight: 600;
  font-size: 36px;
  margin-top: 25px;
  text-align: center;
}
.bodytext{
  color:black;
  font-family: 'poppins';
  font-size: 17px;
  text-align: center ;
  justify-items: center;
  width: 770px;
  margin-top: 0px;

}

.heading{
  color:black;
  font-family: 'poppins';
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  
}

.readmore{
  color:#1C8FE3;
  font-family: 'lato';
  font-size: 15px;
}

/* Header portion */
.carousel-inner img{
  height: 100vh;
  
}

.Tagline{
  margin-left: 5vw;
  margin-top: 10vh;
  color: white;
  position: absolute;
  font-size: 2.8rem;
}
@media (max-width:480px){
  .Tagline{
    font-size: 1.8rem;
    margin-top: 2vh;
  }
}

.first-content{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height :375px;
  line-height: 1vh;
  
}
.paraspace{
  line-height: 4vh;
  /* width: 900px; */
  margin-top: 3vh;
  
}

@media (max-width:480px){
 .heading{
  font-size: 1.8rem;
  width: 90%;
 }
 .content{
  font-size: 1.8rem;
 }
 .bodytext{
  width: 90%;
  font-size: 0.9rem;
  line-height: 3.8vh;
 }
 .first-content{
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
 }
}

.service-section{
  height:auto;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10vh;
  
}
.service-heading{
  color : black;
  font-family: 'Poppins';
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  margin-top: 10vh;
  
}
.service-heading span{
  color:#1c8fe3;
}
.service-text{
  font-family: 'Poppins';
  font-size: 16px;
text-align: center;
color: black;
margin-top: 2vh;

}


.card1{
  background-color: white;
  height: 186px;
  width: 380px;
 text-align: center;
 justify-content: center;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-family: 'poppins';
 box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
 padding: 2vh 0;
}
.card1:hover{
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.account-logo{
  height:75px;
  width:70px;
 /* fill effect from img svg file */

}
.card-heading{
  color:black;
  font-family: 'poppins';
  font-size: 24px;
  font-weight: 600;
  margin-top: 1vh;
}
.card-text{
  color: black;
  font-family: 'poppins';
  font-weight: 500;
  font-size: 12px ;
  width:342px;
  text-align: center;
   
}
.service-line-height{
  line-height: 150%;
}
.card-align{

  display: flex;
  align-items: center;
  gap:6vh;
  margin-top: 6vh;
  
}


/* Service Section Responsive */
@media(max-width:480px){
  .card-align{
    display: flex;
    align-items: center;
    gap:3vh;
    flex-direction: column;
  }
  .card1{
    padding:2vh 3vw;
    width: 87%;

  }
  .card-heading{
    width:90%;
    font-size: 20px;
  }
  .card-text{
    width: 90%;
  }
  .account-logo{
  height: 65px;
  width:60px;
  }
  .service-section{
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    padding-bottom: 5vh;
    padding-top: 5vh;
  }
  .service-text{
    width: 90%;
    font-size: 14px;
    margin-bottom: 0;
  }
  .service-line-height{
    line-height: 150%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  .service-heading{
    margin-top: 0;
  }
  .service-heading{
    font-size: 32px;
  }

}

/* Get Touch Setion */

.query-form-container {
  max-width: 500px;
  max-height:130%;
  margin: 0 ;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family:'poppins';
  font-weight: 400;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  gap: 0;

}
.query-form{
  display: flex;
  flex-direction: column;
  gap:1.5vh;
  margin-top: 4vh;
}

.form-group{
  display: flex;
  flex-direction: column;
  gap:1vh;
}
.form-group textarea{
  height:15vh;
}
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.get-touch{
  display:flex;
  align-items: center;
  justify-content: center;
  height:600px;
  margin-bottom: 10vh;
  margin-top: 10vh;
  gap:10vw;
}
.message{
  color:#1C8FE3;
  font-family: 'Lato';
  margin: 0;
}
.get-heading{
  color: #1C8FE3;
  font-family: 'poppins';
  margin: 0;
}
.get-context{
  color: black;
  font-family: 'Lato';
  margin: 0;
}
.get-align{
  
  margin-right: 80px;
  line-height: 100%;
}
.map{
  margin-right:4vw;
  
}
.query-form button{
  border-style: none;
  background-color: #0D588F;
  color: white;
  font-family: 'poppins';
  padding:2vh 5vw;
}

/* Contact-us-portion */

@media (max-width:480px){

  .get-touch{
    display: flex;
    flex-direction: column;
    /* width:90%; */
    margin-top: 40vh;
    margin-bottom: 50vh;
    align-items: center;
    justify-content: center;
    /* display: none; */
  }
  
  .map{
    margin: 0;
    padding: 0;
    width:100%;

  }
  .map iframe{
    width:380px;
    height: 500px;
    display: flex;
    align-items: center;
    margin-left: 3vw;
  }
  .query-form-container {
    max-height: 150%;
    margin-top: 10vh;
  }

}

/* Footer Section */

.footer-portion{
  background-color: #0D588F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-bottom: 6vh;
 
}
.footer-logo{
  display: flex;
  flex-direction: column;
  gap:2vh;
  justify-items: left;
  padding: 0 2vw;
}
.footer-logo img{
  background-color: #fff;
  padding:8px 10px;
  margin: 0;
  height:10vh;
  width:22vw;
}
.footer-logo p{
  color: white;
  font-family: 'poppins';
  font-size: 16px;
  width:90%;
  margin-top: 1vh;
}
.footer-upper{
  display: flex;
  margin-top: 10vh;
  align-items:flex-start;
  justify-content:center;
  gap:17vw;
  padding:0 2vw;
  width: 100%;

}
.footers-link{
  text-decoration: none;
    color:white;
}
.footer-link{
 display: flex;
 flex-direction: column;
 justify-items: left;

}
.footer-link h1{
  
  margin: 0px;
  padding: 0px;
  font-size: 20px;
  font-family: 'poppins';
  color: #fff;
  font-weight: 400;
  text-wrap: nowrap;
}
.address{
  width:90%;
}
.copyright-section{
 justify-content: left;

  color: white;
}
.useful-link{
display: flex;
flex-direction: column;
gap:2vh;
  color: white;
  font-family: 'lato';
  list-style-type: none;
  font-size:16px;
  padding: 0;
  margin-top: 5vh;
}
.useful-link div{
  display: flex;
  align-items: flex-start;
  gap: 1vw;
}
.footer-lower{
  display: flex;
  align-items: center;
 justify-content: space-between;
 width: 100%;
 padding:0 4vw;
 margin-top: 10vh;
}
.footer-social{
  display: flex;
  gap: 2vw;
}


/* footer responsive */

@media (max-width:480px){

  .footer-portion{
    flex-direction: column;
  }
  .footer-upper{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer-logo{
    align-items: center;
    justify-content: center;
  }
  .footer-logo img{
    width:80vw;
  }
  .footer-logo p{
    text-align: center;
    width: 100%;
  }
  .footer-link{
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .footer-link div{
    align-items: center;
    justify-content: center;
   
  }
  .useful-link{
    gap: 5vh;
  }
  .footer-lower{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5vh;
  }
  .copyright-section{
    text-align: center;
  }
  .footer-social{
    gap:5vw;
  }

}

/* about us section */

.about-header{
  background-image: url("about.jpg") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  width:100%;
  font-family: 'poppins';
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  
}
.about-1-sec{
  display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    gap:150px;
}
.about1-para{
  color:black;
  font-family: 'poppins';
  justify-content: center;
  width:410px;

}
.about1-head{
  color:#1C8FE3;
  font-family: 'poppins';
}
.about-2-sec{
  display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    gap:150px;
}
.about2-para{
  color:black;
  font-family: 'poppins';
  justify-content: center;
  width:410px;

}
.about2-head{
  color:#1C8FE3;
  font-family: 'poppins';
}
.about-3-list{
  color:black;
  font-family: 'poppins';
}
/* about us responsive */

@media (max-width:480px){
  .about-header{
    background-size: cover;
  background-position: center;
  height: 300px;
  width:400px;
  }
  .about-1-sec,.about-2-sec{
    flex-direction: column;
    gap:5vh;
  }
  .about-1-sec div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:2vh;
  }
  .about1-para, .about2-para{
    color:black;
    width: 90%;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  .about-2-sec{
    flex-direction: column;
    text-align: center;
  }
  .about-2-sec div{
    order: 1;
    gap:2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  .about-2-sec img{
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:2vh;
  }

}


/* Result-Section */

.result-section{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18vw;
  background-color: #1C8FE3;
  margin-top: 40px;
  height:200px;
}
.result-no{
  color:white;
  font-family:'poppins';
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  margin: 0;
}
.result-heading{
  color:white;
  font-family:'poppins';
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 0;
}

/* result section responsive */
@media (max-width:480px){

  .result-section{
    flex-direction: column;
    height:auto;
    padding: 8vh 0;
  }
 

}



.values-section{
  margin: 14vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:2vh;
}

.value-head{
  color:#1C8FE3;
  font-family: 'poppins';
  font-weight: 500;
  text-align: center;
 
}
.value-para{
  color:black;
  width:70%;
  text-align: center;
  font-size: 16px;
  font-family: 'poppins';
}
.service-header{
  background-image: url("service.webp") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 300px;
  width:1440px;
  font-family: 'poppins';
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  
}

.service-home-link{
  text-align: center;
  font-size: 20px;
  font-family: 'poppins';
  color: black;
  font-weight: 500;
  font-family:'poppins';
}
.service-para{
  color: black;
  font-family: 'poppins';
  width: 800px;
  text-align: center;
  
}
.service-income{
  font-family: 'poppins';
  text-transform: uppercase;
  text-align: center;
}
.service-content{
  display: flex;
  text-align: center;
  justify-content: center;
}
.subscribe-section{
  color: black;
}

.subscribe-section{
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  height:300px;
  flex-direction: column;
 gap:20px;
  
}
.email-portion{
  display:flex;
  gap:50px;
}
.subscribe-email-box{
  height: 10px;
  width: 400px;
  align-items: center;
  justify-self: center;
}
.Team-header{
  background-image: url("Team.jpg") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 400px;
  width:100%;
  font-family: 'poppins';
  display: flex;
  align-items: center;
  justify-content: center;

  
}

.team-hero-heading{
  color : white;
  font-family: 'Poppins';
  font-size: 36px;
  font-weight: 600;
  text-align: center;
 
}
/* team card styling */

.team-card{
background-color: #ECECEC;
 height: 465px;
 width : 360px;
 margin-top: 10vh;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;


}
.team-img{
  height: 216px;
  width: 216px;
  border-radius: 200px;
}
.team-name{
  font-family: 'poppins';
  font-size: 24px;
  margin-top: 3vh;
}
.team-des{
  font-family: 'poppins';
  font-size: 20px;
  color: #1C8FE3;
}
.team-exp{
  font-family: 'Lato';
  font-size: 16px; 
  color: black;
  margin: 0;
}
.team-ese{
  font-family: 'Lato';
  font-size: 16px;  
  color: black;
  margin-top: 1vh;
}

.team-align{
  display: flex;
  justify-content: center;
  gap: 50px;
}
.card-bar{
background-color:#1C8FE3;
width: 360px;
height:8px;
border-radius: 10px;
margin-top: 0px;
margin-bottom: 30px;
}
.client-hero{
  background-image: url("client.jpg") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 400px;
  width:100%;
  font-family: 'poppins';
  display: flex;
  align-items: center;
  justify-content: center;
  color:white; 
  margin: 0;
  padding: 0;
}
.client-heading{
  color : white;
  font-family: 'Poppins';
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;

}
.client-highlight-para{
  font-family: 'poppins';
  font-size: 20px;
  font-weight: 500;
  color:black;
  text-align: center;
  width: 800px;
  
 
}
.client-industry{
  font-family: 'poppins';
  font-size: 20px;
  width: 80%;
  text-align: center;
  font-weight: 500;
}
.client-industry-subcategory{
  font-family: 'poppins';
  font-size: 16px;
  color: #1C8FE3;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.client-industry-subcategory li{
  text-align: left;
  margin-top: 1vh;
}
.client-industries{
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap:3vw;
}
.client-card{
  width:373px;
  height: 252px;
  box-shadow: -2px 3px 5px lightgrey ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.client-card-align{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap:3vw;
}
.blog-hero{
  background-image: url("blog.jpg") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 200px;
  width:1440px;
  font-family: 'poppins';
  display: flex;
  align-items: center;
  justify-content: center;
  color:white; 
}

/* client section responsive */
@media (max-width:480px){

  .client-highlight-para{
   width: 90%;
  }
  .client-card-align{
    flex-direction: column;
    gap:2vh;
  }
  .client-card{
    width:90vw;
    height:25vh;
  }
  .values-section{
    margin:6vh 0;
  }
  .team-align{
    flex-direction: column;
    gap:0vh;
    align-items: center;
    justify-content: center;
  }
  
}

/* Blog Section */
.blog-image{
  height: 396px;
  width: 816px;
}
.blog-align{
  margin-left: 60px;
  margin-top: 100px;
  line-height: 25px;
  width:850px;
  
}
.blog-category{
 
  font-family: 'poppins';
  color:grey;
}
.blog-heading{
  font-family: 'poppins';
  font-weight: 500;
}
.blog-content{
  color: black;
  width: 816px;
  text-align: left;
  font-family: 'poppins';
}
.blog-readmore{
  color:#1C8FE3;
  font-family: 'poppins';

}
.blog-date{
  color:grey;
  display: flex;
  font-family: 'poppins';
}
.blog-date-section{
  display: flex;
  justify-content: space-between;
  width: 816px;

}

.blog-container{
  display: grid;
  grid-template-columns: 950px 400px;
}

.blog-search{
  margin-top: 100px;
  display: flex;
  align-items: center;
 
}
.blog-search-icon{
position:absolute;
margin-left: 280px;
fill: grey;
height: 30px;
width:30px
}
.blog-search input{
  width: 300px;
  height: 50px;
  border-radius: 100px;
  background-color: #f5f5f5;
  border: none;
  padding-left:30px ;
  color: grey;
}
.blog-search input::placeholder{
  font-size: 18px;
  font-family: 'lato';  
}
.horizontal-line{
background-color: #1C8FE3;
height: 2px;
width: 80px;
margin-left: 0px;

}
.blog-category{
  font-family: 'poppins';
  color: black;
  font-weight: 400;
  font-size: 22px;
}
.blog-sidebar{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.blog-category-list{
  color: black;
  font-family: 'lato';
  font-size: 18px;
  list-style: none;
  margin-left: -40px;
 
}
.blog-category-list li{
  margin-bottom: 20px;
}
.category-card-section{
  background-color: #f5f5f5;
  padding-left: 20px;
  width: 320px;
  height: 740px;
}
.recent-post-section{
  background-color: #f5f5f5;
  padding-left: 20px;
  width: 320px;
  height: 500px;
}

/* Vision - mission */

.Card2{
  height: 225px;
  width:380px;
  display: flex;
  flex-direction: column;
  gap:1.2vh;
  border-radius: 20px;
  background-color: #1c8fe3;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: -10px 10px 10px lightgray;
  background-color : #1c8fe3 ;
  margin-top: 10vh;
  padding-bottom: 2vh;
}
.mission-img{
  height:50px;
  width: 90px;
  margin-top: 3vh;
 
}
.mission-heading{
  font-family:'poppins';
  color:white ;
  font-size:30px;
  text-align: center;
  margin: 0;
}
.mission-text{
  width:342px;
  font-family:'poppins';
  color:white ;
  font-size:15px;
  text-align: center;
  margin: 0;
}

.card2-align{
display:flex;
align-items: center;
justify-content: center;
gap: 3vw;
margin-top: 8vh;
margin-bottom: 18vh;
}

/* vision-mission responsive */

@media (max-width:480px){
.card2-align{
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.Card2{
  padding: 0;
  margin: 0;
}
.card2-align{
  gap:4vh;
  margin-top: 4vh;
  
}
}

/* homepage-team-section */

.team-section{
  margin-top: 10vh;
}
.team-heading{
  font-family: 'poppins';
  font-size: 32px;
  color: black;
  font-weight: 500;
}
.team-heading span{
  color:#1c8fe3;
}
.dir-image{
  
  border-radius: 100%;
  height: 170px;
  width: 170px;
 border-width: 5px;
 border-color: #044CAC;
  
}
.dir-align{
  line-height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:2.5vh;
}
.dir-name{
  font-family: 'poppins';
  font-size: 20px;
  color: black;
  text-align: center;
  margin: 0;
}
.dir-qua{
  font-family: 'poppins';
  font-size: 20px;
  color: #1c8fe3;
  text-align: center;
  margin: 0;
}
.dir-multi{
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 12vh;
  margin-bottom: 15vh;
  gap: 5vw;
}

/*  */
@media (max-width:480px){
  .dir-multi{
  flex-direction: column;
  gap:5vh;
  margin: 0;
  padding:5vh 0;
  }
  .dir-align{
    gap: 2vh;
  }
  .team-section{
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
}

.recent-blog-card{
  height: 435px;
  width: 390px;
  background-color: white;
  display : flex;
  flex-direction: column;
  gap:10px;
  align-items: center;
  margin-top: 30px;

}
.blog-home-head{
font-family: 'poppins';
}
.recent-blogs-portion{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  height: 645px;
  margin-top: 20vh;
  margin-bottom: 10vh;
}
.blog-post-img{
  height:233px;
  width:390px
}
.blog-text{
  color:black;
  font-family: 'poppins';
  width: 342px;
  text-align: center;
}
.blog-home-align{
display: flex;
gap: 30px;
align-items: center;
}

/* contact us */

.contact-hero{
  background-image: url("Contact.jpg") ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 400px;
  width:100%;
  font-family: 'poppins';
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 90%;
}
.contact-card{
  line-height: 180%;
  color: white;
  font-family: 'lato';
  list-style-type: none;
  font-size:16px;
}
.Contact-heading{
  color : white;
  font-family: 'Poppins';
  font-size: 36px;
  font-weight: 600;
  text-align: center;
 
}
.contact-infocard{
  height: 450px;
  width:370px;
  background-color: #0D588F;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vw;
}
.contact-line{
  background-color:white;
  width: 180px;
  height:3px;
  margin-left: 80px;
  margin-top: -20px;
}
.contact-infocard h1 {
  color :white;
  font-family: 'poppins';
  font-weight: 400;
  font-size: 30px;
  

}
.contact-card li {
  line-height: 50px;
  font-size: 20px;
}
.contact-row-1{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  margin-bottom: 15vh;
  gap:15vw;
}
.contact-map{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 100px;
}
.contact-map h1 {
  font-family: 'poppins';
  font-weight: 500;
  margin-bottom: 5vh;
}


.Thankyou-container{
  font-family: 'Poppins';
}

.thankyou-header{
 background-color: #0D588F;
 height:8vh;

}
.thankyou-msg{
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #0D588F;
  gap:1.5vh;
}
.thankyou-msg h1{
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}
.thankyou-msg p{
  font-weight: 400;
  text-align: center;
}
.thankyou-msg button{
  color: white;
  background-color: #0D588F;
  border-style: none;
  padding: 1vh 3vw;
  border-radius: 30px;
}
.modal-backdrop.show{
  display: none;
}


.scroll-button-alignment{
  display: flex;
  flex-direction: column;
  gap:10px;
  justify-content: center;
  align-items: center;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
}

.scroll-button {
  display: inline-block;
  border: none;
  outline: none;
  background-color: #044CAC;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 100px;
  opacity: 0.9;
  height: 60px;
  width: 60px;
 
}

.scroll-button:hover {
  background-color: #333;
}

.top-arrow-style{
  height:30px;
  width:30px;
  
}

.whatsapp-button{
  background-color: transparent;
  border: none;
}
.whatsapp-button img{
  width: 75px;
  height: 75px;
}

@media (max-width:480px){
  .contact-row-1{
    flex-direction: column;
    margin:8vh 0;
    gap:0;
  }
  .contact-infocard .useful-link{
    display: flex;
    flex-direction: column;
    width: 80%;
    
  }
  
  .contact-map iframe{
  width:90%;
  }
}
